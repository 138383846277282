<template>
	<div class="layout-footer">
		<img src="./assets/images/logo1.webp" width="100"  height="38"/>
		<span class="footer-text" style="margin-right: 5px; margin-left: 5px">
            <a href="https://www.facebook.com/pg/SabeeInsurance/posts/" target="_blank" class="p-mr-3">
                <i class="pi pi-facebook" style="fontSize: 1.5rem"></i>
            </a>
            <a href="https://www.instagram.com/sabee_insurance/" target="_blank" style="height:20px;margin-left:5px;margin-right:5px" class="p-link layout-menu-button">
                    <i class="bi bi-instagram" style="fontSize: 1.5rem"></i>
            </a>
		</span>
		<span class="footer-text" style="right: 5px">
			<i class="pi pi-check-circle" style="fontSize: 1.5rem" v-if="this.$store.state.estatus"></i>
            <i class="pi pi-exclamation-circle" style="fontSize: 1.5rem" v-else></i> 2022
			<i class="pi pi-mobile" style="fontSize: 1.5rem" v-if="this.$store.state.mobile"></i>
			<i class="pi pi-desktop" style="fontSize: 1.5rem" v-else></i>
			<span  class="footer-controls" style="fontSize: 1rem">
                V{{ version }} 
            </span>
		</span>
	</div>
</template>

<script>
import packageJson from '../package.json';

export default {
    data() {
        return {
            version: packageJson.version,
		}
	},
}
</script>
