<template>
	<div :class="containerClass" @click="onWrapperClick">
        <Loading/>
        <AppTop @menu-toggle="onMenuToggle"/>   
        <Sidebar v-model:visible="this.$store.state.error" header="Error de Sistema" position="top" style="height: auto" v-if="modo == 'development'">
            <Button :disabled ="$store.state.loading" :label="$t('Cerrar')" icon="pi pi-times" class="p-button-warning" @click="$store.state.error=null"/>
            <p>{{$store.state.error}}</p>
        </Sidebar>
        <transition name="layout-sidebar">
            <div :class="sidebarClass" @click="onSidebarClick" v-show="isSidebarVisible()">
                <div class="layout-logo">
                    <router-link to="/">
                        <img alt="Logo" :src="logo" width="70"/>
                    </router-link>
                </div>
                <AppProfile :user="this.$store.state.usuario" @menu-toggle="onMenuToggle" :layoutColorMode="layoutColorMode" @layout-color-change="onLayoutColorChange" v-if="this.$store.state.authenticated"/>
                <AppMenu :model="this.$store.state.menu[i18n.locale()].principal" @menuitem-click="onMenuItemClick" v-if="this.$store.state.authenticated"/>
            </div>
        </transition>
		<div class="layout-main">
            <Toast/>
			<router-view style="padding: 80px 0.5rem 1rem 0.5rem;"/>
        </div>
		<AppConfig :layoutMode="layoutMode" :layoutColorMode="layoutColorMode"  @layout-color-change="onLayoutColorChange" v-if="this.$store.state.admin"/>
		<AppFooter />
	</div>
</template>

<script>
import AppTop from './AppTop.vue';
import AppProfile from './AppProfile.vue';
import AppMenu from './AppMenu.vue';
import AppConfig from './AppConfig.vue';
import AppFooter from './AppFooter.vue';
import Loading from './components/Loading.vue';
import { useI18nPlugin } from '@unify/vuex-i18n';

export default {
    data() {
        return {
            modo: process.env.NODE_ENV,
            layoutMode: 'static',
            layoutColorMode: 'dark',
            staticMenuInactive: true,
            overlayMenuActive: false,
            mobileMenuActive: false,
            i18n: null,
            
			basicData: {
				labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
				datasets: [
					{
						label: 'My First dataset',
						backgroundColor: '#42A5F5',
						data: [65, 59, 80, 81, 56, 55, 40]
					},
					{
						label: 'My Second dataset',
						backgroundColor: '#9CCC65',
						data: [28, 48, 40, 19, 86, 27, 90]
					}
				]
			}
		
        }
    },
    created() {
        this.i18n = useI18nPlugin();
        this.$store.commit('Estatus');
        this.$store.commit('Mobile');
    },

    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        }
    },
    methods: {
        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
            this.menuClick = false;
        },
        onMenuToggle() {
            this.menuClick = true;
            if(this.$store.state.authenticated){
                if (this.isDesktop()) {
                     this.staticMenuInactive = !this.staticMenuInactive;
                }
                else {
                    this.mobileMenuActive = !this.mobileMenuActive;
                }
                
            } else {
                    if(!this.staticMenuInactive) {
                        this.staticMenuInactive = !this.staticMenuInactive;
                    }
                    if(this.mobileMenuActive) {
                         this.mobileMenuActive = !this.mobileMenuActive;
                    }
            }
            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.staticMenuInactive = true;
                //this.mobileMenuActive = false;
            }
        },
		onLayoutChange(layoutMode) {
			this.layoutMode = layoutMode;
		},
		onLayoutColorChange(layoutColorMode) {
			this.layoutColorMode = layoutColorMode;
		},
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth > 1024;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
                else
                    return true;
            }
            else {
                return true;
            }
        },
    },
    computed: {
        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive,
				'p-input-filled': this.$appState.inputStyle === 'filled',
				'p-ripple-disabled': this.$primevue.ripple === false
            }];
        },
        sidebarClass() {
            return ['layout-sidebar', {
                'layout-sidebar-dark': this.layoutColorMode === 'dark',
                'layout-sidebar-light': this.layoutColorMode === 'light'
            }];
        },
        logo() {
            return (this.layoutColorMode === 'dark') ? "images/boton1.png" : "images/boton2.png";
        }
    },
    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
    components: {
        'AppTop': AppTop,
        'AppProfile': AppProfile,
        'AppMenu': AppMenu,
        'AppConfig': AppConfig,
        'AppFooter': AppFooter,
        'Loading': Loading
    }
}
</script>

<style lang="scss">
@import './App.scss';
</style>
